module.exports = {
  siteTitle: 'Ralph Rouwen', // <title>
  manifestName: 'Ralph Rouwen',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/ralphrouwen.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Ralph Rouwen',
  heading: 'Full stack developer',
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/ralph-rouwen',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:mail@ralphrouwen.nl',
    },
    {
      icon: 'fa-phone',
      name: 'Telefoon',
      url: 'tel:0031610829854',
    },
  ],
};
