import React from 'react';

import config from '../../config';
const pic = require('../assets/images/ralphrouwen.png');

export default function Footer() {
  return (
    <header>
      <span className="avatar">
        <img height="200px" src={pic} alt="" />
      </span>
      <h1>{config.authorName}</h1>
      <p>{config.heading}</p>
        <p style={{textTransform: 'none'}}>Currently developing in React (web), React native (mobile), Symfony and C# (backend) </p>
    </header>
  );
}
